import React from "react";

const ShowHeaderCategories = ({categories}) => {
    return (
        <li className="dropdown">
            <a><span>Categories</span>
                <i className="bi bi-chevron-down dropdown-indicator"></i>
            </a>
            <ul>
                {categories.map((category, index) => (
                    <li key={index}><a href={`/category/${category.id}`}>{category.name}</a></li>
                ))}
            </ul>
        </li>
    );
};

export default ShowHeaderCategories;
