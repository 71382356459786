import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PopularPosts from "./PopularPosts";
import { useParams } from 'react-router-dom';
import {API_BASE_URL} from "../config";

const FetchPopularPosts = () => {
    // State to store the fetched HTML content
    const [popularPosts, setPopularPosts] = useState('');
    const { categoryId } = useParams();
    // Function to fetch data from the database using Axios
    const fetchData = async () => {
        try {
            const url = `${API_BASE_URL}/api/blogposts/all?category_id=${categoryId}`;
            const response = await axios.get(url);
            // const response = await axios.get('http://127.0.0.1:8000/api/blogposts/all');
            // Assuming the HTML content is stored in the 'content' field of the response data
            setPopularPosts(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // useEffect hook to fetch data when the component mounts
    useEffect(() => {
        fetchData();
    }, []); // Empty dependency array ensures the effect runs only once when the component mounts

    return (
        <div>
            {popularPosts ? <PopularPosts popularPosts={popularPosts} /> : <p>Ah! It looks like no popular posts have been added for this category yet....Please come back soon</p>}
        </div>
    );
};

export default FetchPopularPosts;
