import React, { useState } from 'react';
import axios from 'axios';
import {toast} from "react-toastify";
import {API_BASE_URL} from "../config";

const SubmitCommentForm = ({ blogId, onCommentSubmitted }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post(`${API_BASE_URL}/api/submit_comment`, {
                blog_id: blogId,
                name: name,
                email: email,
                comment: message,
            });
            toast.success('Comment submitted successfully!');
            setName('');
            setEmail('');
            setMessage('');
            onCommentSubmitted();
        } catch (error) {
            console.error('Error submitting comment:', error);
            toast.error('Failed to submit comment.');
        }
    };

    return (
        <div className="row justify-content-center mt-5">
            <div className="col-lg-12">
                <h5 className="comment-title">Leave a Comment</h5>
                <form onSubmit={handleSubmit} className="row">
                    <div className="col-lg-6 mb-3">
                        <label htmlFor="comment-name">Name</label>
                        <input type="text" className="form-control" id="comment-name" placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="col-lg-6 mb-3">
                        <label htmlFor="comment-email">Email</label>
                        <input type="text" className="form-control" id="comment-email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="col-12 mb-3">
                        <label htmlFor="comment-message">Message</label>
                        <textarea className="form-control" id="comment-message" placeholder="Enter your comment" cols="30" rows="10" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                    </div>
                    <div className="col-12">
                        <input type="submit" className="btn btn-primary" value="Post comment" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SubmitCommentForm;