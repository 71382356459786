import React from 'react';

const TermAndConditions = ({}) => {
    return (
        <main id="main">
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center mb-5">
                            <h1 className="page-title">T&Cs</h1>
                        </div>
                    </div>

                    <div className="row mb-5">

                        <div className="d-md-flex post-entry-2 half">
                            <div className="ps-md-5 mt-4 mt-md-0">
                                <div className="post-meta mt-4">T&Cs</div>
                                <h2 className="mb-4 display-4">Terms and Conditions</h2>

                                <p>Welcome to My Today's Blog! By accessing our website, you agree to these terms and conditions. Please read them carefully.</p>

                                <h2>1. Intellectual Property Rights</h2>
                                <p>The content published on My Today's Blog, including texts, graphics, images, and other material, is the property of My Today's Blog and its content creators. This content is protected by copyright laws and international copyright treaties, as well as other intellectual property laws and treaties.</p>

                                <h2>2. Use License</h2>
                                <p>(a) Permission is granted to temporarily download one copy of the materials (information or software) on My Today's Blog website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license, you may not:</p>
                                <ul>
                                    <li>Modify or copy the materials;</li>
                                    <li>Use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                                    <li>Attempt to decompile or reverse engineer any software contained on My Today's Blog website;</li>
                                    <li>Remove any copyright or other proprietary notations from the materials; or</li>
                                    <li>Transfer the materials to another person or "mirror" the materials on any other server.</li>
                                </ul>
                                <p>(b) This license shall automatically terminate if you violate any of these restrictions and may be terminated by My Today's Blog at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</p>

                                <h2>3. Disclaimer</h2>
                                <p>The materials on My Today's Blog website are provided on an 'as is' basis. My Today's Blog makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>

                                <h2>4. Accuracy of Materials</h2>
                                <p>The materials appearing on My Today's Blog website could include technical, typographical, or photographic errors. My Today's Blog does not warrant that any of the materials on its website are accurate, complete, or current. My Today's Blog may make changes to the materials contained on its website at any time without notice. However, My Today's Blog does not make any commitment to update the materials.</p>

                                <h2>5. Links</h2>
                                <p>My Today's Blog has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by My Today's Blog of the site. Use of any such linked website is at the user's own risk.</p>

                                <h2>6. Modifications</h2>
                                <p>My Today's Blog may revise these terms and conditions at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms and conditions.</p>

                                <h2>7. Governing Law</h2>
                                <p>These terms and conditions are governed by and construed in accordance with the laws of local region and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>

                                <h2>Contact Us</h2>
                                <p>If you have any questions about these Terms, please contact us.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </main>
    );
};

export default TermAndConditions;


