import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../styling/BlogComments.css';
import { API_BASE_URL } from '../config';
import ScriptExecutor from "./ScriptExecutor";

const BlogComments = ({ refreshComments, onReplySubmitted  }) => {
    const [comments, setComments] = useState([]);
    const [replies, setReplies] = useState({});
    const [loading, setLoading] = useState(false);
    const [replyingTo, setReplyingTo] = useState(null);
    const [replyName, setReplyName] = useState('');
    const [replyEmail, setReplyEmail] = useState('');
    const [replyMessage, setReplyMessage] = useState('');
    const params = useParams();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    // Adjust this function to handle resize events
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const scriptUrl = isMobile
        ? "//tags.orquideassp.com/tag/52469"
        : "//tags.orquideassp.com/tag/52474";
    const htmlContent = `<script src='${scriptUrl}'></script>`;

    const iframeStyle = isMobile
        ? { width: '310px', height: '260px', textAlign: 'center', lineHeight: '100%', color: '#333', border: '1px solid white' }
        : { width: '750px', height: '115px', textAlign: 'center', lineHeight: '100%', color: '#333', border: '1px solid white' };

    const submitReply = async (commentId) => {
        if (!replyName.trim() || !replyEmail.trim() || !replyMessage.trim()) {
            toast.error('Please fill in all fields.');
            return;
        }
        try {
            const response = await axios.post(`${API_BASE_URL}/api/submit_reply`, {
                comment_id: commentId,
                name: replyName,
                email: replyEmail,
                reply: replyMessage, // assuming the backend expects a 'reply' key for the message
            });
            // Handle success response
            toast.success('Reply submitted successfully!');
            setReplyingTo(null); // Close the reply box
            setReplyName(''); // Clear the name input
            setReplyEmail(''); // Clear the email input
            setReplyMessage(''); // Clear the reply message input
            onReplySubmitted(); // Trigger a refresh of comments to show the new reply
        } catch (error) {
            console.error('Error submitting reply:', error);
            toast.error('Failed to submit reply.');
        }
    };

    useEffect(() => {
        const fetchCommentsAndReplies = async () => {
            setLoading(true);
            try {
                const baseUrl = `${API_BASE_URL}/api/get-comments`;
                const url = `${baseUrl}?${params.blogId ? `blog_id=${params.blogId}` : `category_id=${params.categoryId}`}`;
                const response = await axios.get(url);
                setComments(response.data.comments);
                setReplies(response.data.replies);
            } catch (error) {
                console.error('Error fetching comments:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCommentsAndReplies();
    }, [params.blogId, refreshComments, onReplySubmitted]);

    if (loading) {
        return <p>Loading comments...</p>;
    }

    return (
        <div className="comments">
            <ScriptExecutor htmlContent={htmlContent} style={iframeStyle} />
            <h5 className="comment-title py-4">{comments.length} Comments</h5>
            <ScriptExecutor htmlContent={htmlContent} style={iframeStyle} />

            {comments.map((comment) => (
                <div key={comment.id} className="comment d-flex mb-4">
                    <div className="flex-shrink-0">
                        <div className="avatar avatar-sm rounded-circle">
                            <img className="avatar-img" src={comment.avatarUrl} alt="Avatar" />
                        </div>
                    </div>
                    <div className="flex-grow-1 ms-2 ms-sm-3">
                        <div className="comment-meta d-flex align-items-baseline">
                            <h6 className="me-2">{comment.name}</h6>
                            <span className="text-muted">{comment.timestamp}</span>
                        </div>
                        <div className="comment-body">
                            {comment.comment}
                            <div className="comment-actions">
                                <span className="reply-link" onClick={() => setReplyingTo(comment.id)}>Reply</span>
                            </div>
                            {replyingTo === comment.id && (
                                <div className="reply-form">
                                    <input
                                        type="text"
                                        placeholder="Your name"
                                        value={replyName}
                                        onChange={(e) => setReplyName(e.target.value)}
                                        className="reply-name"
                                    />
                                    <input
                                        type="email"
                                        placeholder="Your email"
                                        value={replyEmail}
                                        onChange={(e) => setReplyEmail(e.target.value)}
                                        className="reply-email"
                                    />
                                    <textarea
                                        className="reply-message"
                                        value={replyMessage}
                                        onChange={(e) => setReplyMessage(e.target.value)}
                                        placeholder="Write your reply here..."
                                    ></textarea>
                                    <button className="submit-reply-btn" onClick={() => submitReply(comment.id)}>
                                        Submit Reply
                                    </button>
                                </div>
                            )}
                        </div>
                        {replies[comment.id] && replies[comment.id].length > 0 && (
                            <div className="comment-replies bg-light p-3 mt-3 rounded">
                                <h6 className="comment-replies-title mb-4 text-muted text-uppercase">
                                    {replies[comment.id].length} replies
                                </h6>

                                {replies[comment.id].map((reply) => (
                                    <div key={reply.id} className="reply d-flex mb-4">
                                        <div className="flex-shrink-0">
                                            <div className="avatar avatar-sm rounded-circle">
                                                <img className="avatar-img" src={reply.avatarUrl} alt="Avatar" />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 ms-2 ms-sm-3">
                                            <div className="reply-meta d-flex align-items-baseline">
                                                <h6 className="mb-0 me-2">{reply.name}</h6>
                                                <span className="text-muted">{reply.timestamp}</span>
                                            </div>
                                            <div className="reply-body">
                                                {reply.reply}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default BlogComments;