import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BlogContainer from "./BlogContainer";
import HomePage from "./components/HomePage";
import BlogManager from "./components/BlogManager"; // Corrected path for BlogManager
import Header from "./components/Header";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import AboutUs from "./components/AboutUs";
import BlogList from "./components/admin/BlogList"; // Correct path for BlogList
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpdateBlog from "./components/admin/UpdateBlog"; // Correct path for UpdateBlog
import TandCs from "./components/TandCs";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from './components/admin/Login'; // Correct path for Login
import { useAuth } from './context/AuthContext';

function App() {
    const { user, loading } = useAuth();

    if (loading) return <div>Loading...</div>;

    return (
        <div className="App">
            <header className="App-header">
                <Header />
            </header>
            <main id="main">
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                    path="/admin/add-blog"
                    element={
                        <ProtectedRoute isAdmin={user?.role === 'admin'}>
                            <BlogManager />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/admin/edit-blog/:blogId"
                    element={
                        <ProtectedRoute isAdmin={user?.role === 'admin'}>
                            <UpdateBlog />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/admin/blogs-list"
                    element={
                        <ProtectedRoute isAdmin={user?.role === 'admin'}>
                            <BlogList />
                        </ProtectedRoute>
                    }
                />
                <Route path="/" element={<BlogContainer />} />
                <Route path="/blog/:blogId" element={<BlogContainer />} />
                <Route path="/contact-us" element={<Contact />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/t&c's" element={<TandCs />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/category/:categoryId" element={<BlogContainer />} />
            </Routes>
            </main>
            <footer className="App-footer">
                <Footer />
            </footer>
            <ToastContainer />
        </div>
    );
}

export default App;
