import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {API_BASE_URL} from "../config";

const CategoriesDropDown = ({ onCategoryChange }) => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/blogpost/categories`);
                setCategories(response.data); // Set the categories data from the response
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    return (
        <select
            value={selectedCategory}
            onChange={e => {
                setSelectedCategory(e.target.value);
                onCategoryChange(e.target.value);
            }}
            className="form-control"
            style={{
                margin: '10px 0',
                padding: '8px',
                width: '80%',
                borderRadius: '5px',
                border: '1px solid #ccc',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
            }}
            required
        >
            <option value="">Select a category</option>
            {categories.map(category => (
                <option key={category.id} value={category.id}>{category.name}</option>
            ))}
        </select>
    );
};

export default CategoriesDropDown;
