import React, { useState } from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {API_BASE_URL} from "../config";

const Contact = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
    });
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [sentMessage, setSentMessage] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage("");
        setSentMessage(false);

        try {
            await axios.post(`${API_BASE_URL}/api/contact_us`, formData);
            setLoading(false);
            setSentMessage(true);

            toast.success('Your message is sent. Thanks for contacting.We will get back to you soon.');
            // Clear the form fields
            setFormData({
                name: "",
                email: "",
                subject: "",
                message: "",
            });
        } catch (error) {
            console.error("Failed to submit contact form: ", error);
            setLoading(false);
            setErrorMessage("Failed to send message. Please try again later.");
        }
    };

    return (
        <main id="main">
            <section id="contact" className="contact mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center mb-5">
                            <h1 className="page-title">Contact us</h1>
                        </div>
                    </div>

                    <div className="row gy-4">
                        <div className="col-md-4">
                            {/*<div className="info-item">*/}
                            {/*    <i className="bi bi-envelope"></i>*/}
                            {/*    <h3>Email</h3>*/}
                            {/*    <p><a href="mailto:info@example.com">sikander789829@gmail.com</a></p>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    <div className="form mt-5">
                        <form onSubmit={handleSubmit} className="php-email-form">
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <input type="text" name="name" className="form-control" id="name"
                                           placeholder="Your Name" required value={formData.name} onChange={handleChange} />
                                </div>
                                <div className="form-group col-md-6">
                                    <input type="email" className="form-control" name="email" id="email"
                                           placeholder="Your Email" required value={formData.email} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" name="subject" id="subject"
                                       placeholder="Subject" required value={formData.subject} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" name="message" rows="5" placeholder="Message"
                                          required value={formData.message} onChange={handleChange}></textarea>
                            </div>

                            <div className="my-3">
                                {loading && <div className="loading">Loading</div>}
                                {errorMessage && <div className="error-message">{errorMessage}</div>}
                                {sentMessage && <div className="sent-message">Your message has been sent. Thank you!</div>}
                            </div>

                            <div className="text-center">
                                <button type="submit">Send Message</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Contact;
