import React, { useState, useEffect } from 'react';
import SinglePost from "./components/SinglePost";
import AddMetaTags from '../src/components/AddMetaTags';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from './config';
const BlogContainer = () => {
    const [post, setPost] = useState({
        title: '',
        summary: '',
        keywords: '',
        category_id: null,
        blog_id: null,
    });
    const params = useParams();

    useEffect(() => {
        const fetchPost = async () => {
            // Construct URL based on params
            const url = `${API_BASE_URL}/api/blogpost?${params.blogId ? `blog_id=${params.blogId}` : `category_id=${params.categoryId}`}`;
            try {
                const response = await axios.get(url);
                setPost(response.data);
            } catch (error) {
                console.error('Error fetching post:', error);
            }
        };
        const trackVisit = async () => {
            try {
                // This should be your API endpoint to track visits
                const visitResponse = await axios.get(`${API_BASE_URL}/api/check-visit`);
                if (visitResponse.data.isNewVisitor) {
                    console.log("Welcome, new visitor!");
                } else {
                    console.log("Welcome back!");
                }
            } catch (error) {
                console.error('Error tracking visit:', error);
            }
        };

        fetchPost();
        trackVisit();
    }, [params.blogId, params.categoryId]);
    return (
        <div>
            <AddMetaTags post={post} />
            {<SinglePost/>}
        </div>
    );
};


export default BlogContainer;
