import React, { useState } from 'react';
import GetHeaderCategories from "./GetHeaderCategories";
import '../styling/HeaderNavbar.css';
import { Link } from 'react-router-dom';

const Header = () => {
    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    return (
        <header id="header" className="header d-flex align-items-center fixed-top">
            <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

                <a href="/" className="logo d-flex align-items-center">
                    {/* Uncomment the line below if you also wish to use an image logo */}
                    {/* <img src="assets/img/logo.png" alt=""> */}
                    <h1>My Today's Blog</h1>
                </a>

                <nav id="navbar" className="navbar">
                    <ul>
                        {/* <li><a href="index.html">Blog</a></li> */}
                        <li><Link to="/">Today's Blog</Link></li>
                        <GetHeaderCategories/> {/* Assuming GetHeaderCategories already returns Links */}
                        <li><Link to="/about-us">About Us</Link></li>
                        <li><Link to="/t&c's">T&Cs</Link></li>
                        <li><Link to="/contact-us">Contact Us</Link></li>
                    </ul>
                </nav>
                {showPopup && (
                    <div className="fullscreen-popup">
                        {/* Your popup content here */}
                        <nav className="fullscreen-nav">
                            <ul>
                                {/* <li><a href="index.html">Blog</a></li> */}
                                <li><Link to="/">Today's Blog</Link></li>
                                <GetHeaderCategories/>
                                <li><Link to="/about-us">About Us</Link></li>
                                <li><Link to="/t&c's">T&Cs</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                            </ul>
                        </nav>
                        <button onClick={togglePopup} className="close-popup">Close</button>
                    </div>
                )}

                <div className="position-relative">
                    <span className="mx-2">B</span>
                    <span  className="mx-2">L</span>
                    <span  className="mx-2">O</span>

                    <span  className="mx-2 js-search-open">G</span>
                    <i className="bi bi-list mobile-nav-toggle" onClick={togglePopup}></i>

                    <div className="search-form-wrap js-search-form-wrap">
                        <form action="#/" className="search-form">
                            <span className="icon bi-search"></span>
                            <input type="text" placeholder="Search" className="form-control"/>
                            <button className="btn js-search-close"><span className="bi-x"></span></button>
                        </form>
                    </div>

                </div>

            </div>

        </header>
    );
}

export default Header;
