import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { API_BASE_URL, BASE_URL } from '../config';

const FetchSinglePost = ({ setBlogId }) => {
    const [htmlContent, setHtmlContent] = useState('');
    const params = useParams();

    const constructUrl = () => {
        const baseUrl = `${API_BASE_URL}/api/blogpost`;
        return `${baseUrl}?${params.blogId ? `blog_id=${params.blogId}` : `category_id=${params.categoryId}`}`;
    };

    const fetchData = async () => {
        try {
            const url = constructUrl();
            const response = await axios.get(url);
            setHtmlContent(decodeHtmlEntities(response.data.content));
            setBlogId(response.data.blog_id);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [params.blogId, setBlogId]);

    const decodeHtmlEntities = (text) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
    };

    return (
        <div>
            {htmlContent ? (
                <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            ) : (
                <h2>Ah! It looks like no blogs have been added for this category yet....Please come back soon</h2>
            )}
        </div>
    );
};

export default FetchSinglePost;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import { API_BASE_URL, BASE_URL } from '../config';
//
// const FetchSinglePost = ({ setBlogId }) => {
//     const [htmlContent, setHtmlContent] = useState('');
//     const params = useParams();
//
//     const constructUrl = () => {
//         const baseUrl = `${API_BASE_URL}/api/blogpost`;
//         return `${baseUrl}?${params.blogId ? `blog_id=${params.blogId}` : `category_id=${params.categoryId}`}`;
//     };
//
//     const fetchData = async () => {
//         try {
//             const url = constructUrl();
//             const response = await axios.get(url);
//             const modifiedHtmlContent = insertAdsIntoContent(decodeHtmlEntities(response.data.content));
//             setHtmlContent(modifiedHtmlContent);
//             setBlogId(response.data.blog_id);
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };
//
//     const insertAdsIntoContent = (content) => {
//         // Example ad tag - replace this with your actual ad tag
//         const adHtml = '<div class="ad"><script src=\'//tags.orquideassp.com/tag/52471\'></script></div>';
//
//         // Split the content by paragraphs and insert the ad after each paragraph
//         // This is a simple example; you might need a more complex approach
//         const paragraphs = content.split('</p>');
//         const contentWithAds = paragraphs.map((p) => p ? `${p}</p>${adHtml}` : '').join('');
//
//         return contentWithAds;
//     };
//
//
//     useEffect(() => {
//         fetchData();
//     }, [params.blogId, setBlogId]);
//
//     const decodeHtmlEntities = (text) => {
//         const textArea = document.createElement('textarea');
//         textArea.innerHTML = text;
//         return textArea.value;
//     };
//
//     return (
//         <div>
//             {htmlContent ? (
//                 <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
//             ) : (
//                 <h2>Ah! It looks like no blogs have been added for this category yet....Please come back soon</h2>
//             )}
//         </div>
//     );
// };
//
// export default FetchSinglePost;
