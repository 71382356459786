import React from 'react';

const PrivacyPolicy = ({}) => {
    return (
        <main id="main">
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center mb-5">
                            <h1 className="page-title">Privacy Policy</h1>
                        </div>
                    </div>

                    <div className="row mb-5">

                        <div className="d-md-flex post-entry-2 half">
                            <div className="ps-md-5 mt-4 mt-md-0">
                                <h1>Privacy Policy</h1>

                                <p>Effective date: 04-21-2022</p>

                                <p>At My Today's Blog, accessible from https://my-blogs.today/, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by My Today's Blog and how we use it.</p>

                                <h2>1. Information Collection</h2>
                                <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

                                <h3>Personal Data</h3>
                                <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
                                <ul>
                                    <li>Email address</li>
                                    <li>First name and last name</li>
                                    <li>Phone number</li>
                                    <li>Address, State, Province, ZIP/Postal code, City</li>
                                    <li>Cookies and Usage Data</li>
                                </ul>

                                <section>
                                    <h2>2. How We Use Your Information</h2>
                                    <p>The information we collect is used in various ways, including to:</p>
                                    <ul>
                                        <li>Provide, operate, and maintain our website</li>
                                        <li>Improve, personalize, and expand our website</li>
                                        <li>Understand and analyze how you use our website</li>
                                        <li>Develop new products, services, features, and functionality</li>
                                        <li>Communicate with you, directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                                        <li>Send you emails with the temporary link to access your uploaded picture</li>
                                        <li>Find and prevent fraud</li>
                                    </ul>
                                </section>

                                <section>
                                    <h2>3. Log Files</h2>
                                    <p>
                                        <strong>My Today's Blog</strong> follows a standard procedure of using log files. These files log visitors when they visit websites. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. This data is not linked to any personally identifiable information. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
                                    </p>
                                </section>

                                <section>
                                    <h2>4. Information Collection and Use</h2>
                                    <p>"Our commitment to your privacy is paramount; we do not collect any personal data or information from our users."</p>
                                    <p>"We believe in complete transparency and assure you that we do not engage in any form of data collection."</p>
                                    <p>"Your trust is important to us, so we maintain a strict no data collection policy to ensure your privacy is protected."</p>
                                    <p>"Rest assured, your usage of our service remains private, as we do not gather any information for any purpose."</p>
                                    <p>"As part of our dedication to privacy, we guarantee that no personal data is recorded, stored, or used by our platform."</p>
                                </section>

                                <section>
                                    <h2>5. Log Data & Cookies</h2>
                                    <p>Our platform operates without collecting Log Data; your visits are free from any form of tracking, including IP addresses or browser details.</p>
                                    <p>We respect your right to privacy; thus, we do not use cookies or gather browsing information like browser type or version.</p>
                                    <p>Your online footprint remains yours alone—we do not track or store any Log Data, nor do we deploy cookies for data collection purposes.</p>
                                    <p>Browse with peace of mind, knowing that we abstain from collecting cookies, Log Data, or any other tracking information.</p>
                                    <p>To uphold the highest privacy standards, our service is designed to function without acquiring any Log Data, using cookies, or tracking user activities.</p>

                                </section>

                                <section>
                                    <h2>6. Security</h2>
                                    <p>The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure.But we made our 100 percent efforts to keep it secure</p>
                                </section>

                                <h2>Conclusion</h2>
                                <p>Rest assured, the integrity and security of your data is our top priority; we take extensive measures to protect it.</p>
                                <p>We have a steadfast commitment to privacy—we neither use your data beyond the provision of our services nor share it with third parties.</p>
                                <p>Your confidence in our service is valued, and we honor it by not exploiting any data you provide.</p>
                                <p>Trust is the cornerstone of our service, with a promise that your personal information will always remain just that—personal and private.</p>
                                <p>We stand by our principle of user privacy; your data is not commoditized, traded, or sold—it is strictly safeguarded within our secure infrastructure.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </main>
    );
};

export default PrivacyPolicy;


