// File path: src/components/admin/BlogList.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styling/BlogList.css';
import { useNavigate } from 'react-router-dom';
import {API_BASE_URL} from "../../config";

const BlogList = () => {
    const [blogs, setBlogs] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Function to fetch all blogs from the backend
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/blogposts/all`);
                setBlogs(response.data);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);

    const handleEdit = (blogId) => {
        // Use navigate function to redirect
        navigate(`/admin/edit-blog/${blogId}`);
    };

    return (
        <div className="blog-list-container">
            <h1>Blog Posts</h1>
            <ul>
                {blogs.map((blog, index) => (
                    <li key={index} className="blog-list-item">
                        <h2>{blog.title}</h2>
                        <p>{blog.summary}</p>
                        <p>Category: {blog.category}</p>
                        <button onClick={() => handleEdit(blog.id)}>Edit</button>
                    </li>
                ))}
            </ul>
        </div>
    );

};

export default BlogList;
