import React, { useState } from 'react';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import { API_BASE_URL } from '../config';

function ImageUpload() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageURL, setImageURL] = useState('');

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (selectedFile) {
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            };

            try {
                const compressedFile = await imageCompression(selectedFile, options);
                const formData = new FormData();
                formData.append('file', compressedFile);

                const response = await axios.post(`${API_BASE_URL}/api/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                setImageURL(response.data.url);
                const imgTag = `<img src="${response.data.url}" alt="Descriptive alt text" />`;
                alert(`Image Tag: ${imgTag}\n\nClick OK to copy to clipboard.`);
                navigator.clipboard.writeText(imgTag);

            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };

    return (
        <div>
            <input type="file" accept="image/*" onChange={handleFileSelect} />
            <button onClick={handleUpload}>Upload Image</button>
            {imageURL && (
                <div style={{ width: '400px', height: '400px', position: 'relative' }}>
                    <img src={imageURL} alt="Uploaded" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                    <p>
                        <button
                            onClick={() => navigator.clipboard.writeText(`<img src="${imageURL}" alt="Uploaded" />`)}
                            style={{ marginRight: '10px' }}
                        >
                            Copy
                        </button>
                        {imageURL}
                    </p>
                </div>
            )}
        </div>
    );
}

export default ImageUpload;
