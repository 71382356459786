import React, {useEffect, useState} from 'react';
import FetchSinglePost from "./FetchSinglePost";
import FetchPopularPosts from "./FetchPopularPosts";
import Fetchcategories from "./FetchCategories";
import '../styling/SinglePost.scss';
import BlogComments from "./BlogComments";
import CommentForm from "./CommentForm";
import ScriptExecutor from "./ScriptExecutor";

const SinglePost = () => {
    const [blogId, setBlogId] = useState('');
    const [refreshComments, setRefreshComments] = useState(false);
    const [refreshCommentsTrigger, setRefreshCommentsTrigger] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    // Adjust this function to handle resize events
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const onReplySubmitted = () => {
        // This will toggle the state, which can be used as a useEffect dependency
        // in BlogComments to re-fetch comments
        setRefreshCommentsTrigger(!refreshCommentsTrigger);
    };
    const triggerCommentsRefresh = () => {
        setRefreshComments(prevState => !prevState);
    };

    const scriptUrl = isMobile
        ? "//tags.orquideassp.com/tag/52469"
        : "//tags.orquideassp.com/tag/52474";
    const htmlContent = `<script src='${scriptUrl}'></script>`;

    const iframeStyle = isMobile
        ? { width: '310px', height: '260px', textAlign: 'center', lineHeight: '100%', color: '#333', border: '1px solid white' }
        : { width: '750px', height: '115px', textAlign: 'center', lineHeight: '100%', color: '#333', border: '1px solid white' };

    const sideAd1 = '//tags.orquideassp.com/tag/52467';
    const siderBarHtml1 = `<script src='${sideAd1}'></script>`;
    const side1style = { width: '160px', height: '600px', marginLeft: '-9%', textAlign: 'center', color: '#333', border: '1px solid #ddd' };

    return (
        <main id="main">
            <section className="single-post-content">
                <div className="container">
                    {/*<ScriptExecutor htmlContent={siderBarHtml1} style={side1style} />*/}
                    <div className="row">
                        <div className="col-md-9 post-content">
                            <ScriptExecutor htmlContent={htmlContent} style={iframeStyle} />
                            <FetchSinglePost setBlogId={setBlogId}/>
                            <BlogComments blogId={blogId} refreshComments={refreshComments} onReplySubmitted={onReplySubmitted} />
                            <CommentForm blogId={blogId} onCommentSubmitted={triggerCommentsRefresh}/>
                        </div>
                        <div className="col-md-3">
                            <div className="aside-block">
                                <ul className="nav nav-pills custom-tab-nav mb-4" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-popular-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-popular" type="button" role="tab"
                                                aria-controls="pills-popular" aria-selected="true">Popular
                                        </button>
                                    </li>
                                </ul>

                                <div className="tab-content" id="pills-tabContent">
                                    <FetchPopularPosts/>
                                </div>
                            </div>

                            <div className="aside-block">
                                <h3 className="aside-title">Categories</h3>
                                 <Fetchcategories/>
                            </div>

                            {/*<div className="aside-block">*/}
                            {/*    <h3 className="aside-title">Tags</h3>*/}
                            {/*    <ul className="aside-tags list-unstyled">*/}
                            {/*        <li><a href="category.html">Business</a></li>*/}
                            {/*        <li><a href="category.html">Culture</a></li>*/}
                            {/*        <li><a href="category.html">Sport</a></li>*/}
                            {/*        <li><a href="category.html">Food</a></li>*/}
                            {/*        <li><a href="category.html">Politics</a></li>*/}
                            {/*        <li><a href="category.html">Celebrity</a></li>*/}
                            {/*        <li><a href="category.html">Startups</a></li>*/}
                            {/*        <li><a href="category.html">Travel</a></li>*/}
                            {/*    </ul>*/}
                            {/*</div>*/}

                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default SinglePost;
