import React, { useEffect, useRef } from 'react';

function ScriptExecutor({ htmlContent, style }) {
    const iframeRef = useRef(null);

    useEffect(() => {
        const iframe = iframeRef.current;
        if (iframe) {
            // Prepare the iframe document's content
            const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
            iframeDoc.open();
            iframeDoc.write(htmlContent);
            iframeDoc.close();
        }
    }, [htmlContent]); // Re-run the effect if htmlContent changes

    return <iframe ref={iframeRef} style={style} title="ScriptExecutor"></iframe>;
}

export default ScriptExecutor;
