import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer id="footer" className="footer">
            <div className="footer-content">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-4">
                            <h3 className="footer-heading">My Today's Blog</h3>
                            <p>We 'My Today's Blogs' have been writing quality content since 2 years. We try to cover broad range of topics
                                including Food ,travel and others. We provide an inside knowledge of every industry and
                                try to cover topics deeply.</p>
                            {/*<p><a href="about.html" className="footer-link-more">Learn More</a></p>*/}
                        </div>
                        <div className="col-6 col-lg-2">
                            <h3 className="footer-heading">Navigation</h3>
                            <ul className="footer-links list-unstyled">
                                <li><Link to="/"><i className="bi bi-chevron-right"></i> Home</Link></li>
                                <li><Link to="/about-us"><i className="bi bi-chevron-right"></i> About Us</Link></li>
                                <li><Link to="/contact-us"><i className="bi bi-chevron-right"></i> Contact Us</Link></li>
                                <li><Link to="/t&c's"><i className="bi bi-chevron-right"></i> Terms & Conditions</Link></li>
                                <li><Link to="/privacy-policy"><i className="bi bi-chevron-right"></i> Privacy Policy</Link></li>
                            </ul>
                        </div>

                        {/* Add other sections here */}
                    </div>
                </div>
            </div>
            <div className="footer-legal">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            <div className="copyright">
                                © Copyright <strong><span>My Today's Blog</span></strong>. All Rights Reserved
                            </div>
                            <div className="credits">
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="social-links mb-3 mb-lg-0 text-center text-md-end">
                                {/* Social links here */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
