import React from "react";

const ShowCategories = ({categories}) => {
    return (
        <ul className="aside-links list-unstyled">
        {categories.map((category, index) => (
                    <li key={index}>
                        <a href={`/category/${category.id}`}><i className="bi bi-chevron-right"></i> {category.name}</a>
                    </li>
            ))}
        </ul>
    );
};

export default ShowCategories;