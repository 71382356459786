import '../styling/BlogManager.scss';
import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include the Quill CSS
import ImageUpload from "./ImageUpload";
import CategoriesDropDown from "./CategoriesDropDown";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from '../config';

const BlogManager = () => {
    const [title, setTitle] = useState('');
    const [keywords, setKeywords] = useState('');
    const [category, setCategory] = useState('');
    const [content, setContent] = useState('');
    const [summary, setSummary] = useState('');
    const { blogId } = useParams();
    const navigate = useNavigate();

    const handleCategoryChange = (newCategory) => {
        setCategory(newCategory);
    };

    const fetchLatestBlogPost = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${API_BASE_URL}/api/admin/blogpost?blog_id=${blogId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const latestBlogPost = response.data;
            setTitle(latestBlogPost.title);
            setKeywords(latestBlogPost.keywords);
            setCategory(latestBlogPost.category);
            setContent(latestBlogPost.content);
            setSummary(latestBlogPost.summary);
        } catch (error) {
            console.error("Could not fetch the latest blog post:", error);
            if (error.response && error.response.status === 401) {
                navigate('/login');
            }
        }
    };

    useEffect(() => {
        fetchLatestBlogPost();
    }, []);

    const handleSubmit = async () => {
        const blogData = {
            title,
            content,
            summary,
            keywords,
            category,
        };

        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${API_BASE_URL}/api/submit-blogpost`, blogData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });
            console.log(response.data);
        } catch (error) {
            console.error("Could not submit blog post:", error);
            if (error.response && error.response.status === 401) {
                navigate('/login');
            }
        }
    };

    return (
        <div className="blog-manager my-custom-mt">
            <label>Title</label>
            <input
                type="text"
                value={title}
                onChange={e => setTitle(e.target.value)}
                placeholder="Title"
            />

            <label>Summary</label>
            <input
                type="text"
                value={summary}
                onChange={e => setSummary(e.target.value)}
                placeholder="Give a One sentence summary."
            />

            <label>Select Category</label>
            <CategoriesDropDown onCategoryChange={handleCategoryChange} />

            <label>Keywords</label>
            <input
                type="text"
                value={keywords}
                onChange={e => setKeywords(e.target.value)}
                placeholder="Enter comma separated keywords."
            />

            <label className="mt-5">Upload Images (Upload images here and paste the link in editor.)</label>
            <ImageUpload className="mt-5 mb-5"/>

            <label className="mt-5">Post</label>
            <div className="rich-text-editor">
                <ReactQuill value={content} onChange={setContent} />
            </div>

            <button onClick={handleSubmit}>Submit Blog Post</button>
        </div>
    );
};

export default BlogManager;
