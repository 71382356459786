import React from 'react';

const AboutUs = ({}) => {
    return (
        <main id="main">
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center mb-5">
                            <h1 className="page-title">About us</h1>
                        </div>
                    </div>

                    <div className="row mb-5">

                        <div className="d-md-flex post-entry-2 half">
                            <a href="#" className="me-4 thumbnail">
                                <img src="assets/img/post-landscape-2.jpg" alt="" className="img-fluid" />
                            </a>
                            <div className="ps-md-5 mt-4 mt-md-0">
                                <div className="post-meta mt-4">About us</div>
                                <h2 className="mb-4 display-4">Blogs History</h2>

                                <p>We 'My Today's Blogs' have been writing quality content since 2 years.
                                    We try to cover broad range of topics including Food ,travel and others. We provide an
                                    inside knowledge of every industry and try to cover topics deeply.
                                </p>
                                <p></p>
                            </div>
                        </div>

                        <div className="d-md-flex post-entry-2 half mt-5">
                            <a href="#" className="me-4 thumbnail order-2">
                                <img src="assets/img/post-landscape-1.jpg" alt="" className="img-fluid" />
                            </a>
                            <div className="pe-md-5 mt-4 mt-md-0">
                                <div className="post-meta mt-4">Mission &amp; Vision</div>
                                <h2 className="mb-4 display-4">Mission &amp; Vision</h2>

                                <p>Our Vision is to become a huge knowledge base.</p>
                                <p></p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </main>
    );
};

export default AboutUs;


