import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ShowCategories from "./ShowCategories";
import {API_BASE_URL} from "../config";

const Fetchcategories = () => {
    // State to store the fetched HTML content
    const [categories, setCategories] = useState('');

    // Function to fetch data from the database using Axios
    const fetchData = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/blogpost/categories`);
            // Assuming the HTML content is stored in the 'content' field of the response data
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // useEffect hook to fetch data when the component mounts
    useEffect(() => {
        fetchData();
    }, []); // Empty dependency array ensures the effect runs only once when the component mounts

    return (
        <div>
            {categories ? <ShowCategories categories={categories} /> : <p>Loading categories... try refresh</p>}
        </div>
    );
};

export default Fetchcategories;
