// import React, { useState, useEffect } from 'react';
// import { Helmet } from 'react-helmet-async';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import { API_BASE_URL } from '../config';
// import { useLocation } from 'react-router-dom';
//
// const BlogPost = () => {
//     const location = useLocation();
//     const params = useParams();
//     const [post, setPost] = useState({
//         title: '',
//         summary: '',
//         keywords: '',
//         category_id: null,
//         blog_id: null,
//     });
//
//     const constructUrl = () => {
//         const baseUrl = `${API_BASE_URL}/api/blogpost`;
//         return `${baseUrl}?${params.blogId ? `blog_id=${params.blogId}` : `category_id=${params.categoryId}`}`;
//     };
//
//
//     const fetchData = async () => {
//         try {
//             const url = constructUrl();
//             const response = await axios.get(url);
//
//             setPost({
//                 title: response.data.title || '',
//                 summary: response.data.summary || '',
//                 keywords: response.data.keywords || '',
//                 category_id: response.data.category_id || null,
//                 blog_id: response.data.blog_id || null,
//             });
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };
//
//     useEffect(() => {
//         fetchData();
//     }, [location, params.blogId, params.categoryId]); // React to changes in location, blogId, and categoryId
//
//     return (
//         <div>
//             <Helmet>
//                 <title>{post.title || 'Default Title'}</title>
//                 <meta name="description" content={post.summary || 'Default summary'} />
//                 <meta name="keywords" content={post.keywords || 'default,keywords'} />
//                 {post.category_id && (
//                     <link
//                         rel="canonical"
//                         href={`https://my-blogs.today/category/${post.category_id}`}
//                     />
//                 )}
//                 {post.blog_id && (
//                     <link
//                         rel="canonical"
//                         href={`https://my-blogs.today/blog/${post.blog_id}`}
//                     />
//                 )}
//             </Helmet>
//             {/*<h1>{post.title}</h1>*/}
//             {/*<p>{post.summary}</p>*/}
//         </div>
//     );
// };
//
// export default BlogPost;
// AddMetaTags.js
import React from 'react';
import { Helmet } from 'react-helmet-async';

const AddMetaTags = ({ post }) => {
    return (
        <Helmet>
            <title>{post.title || 'Default Title'}</title>
            <meta name="description" content={post.summary || 'Default summary'} />
            <meta name="keywords" content={post.keywords || 'default,keywords'} />
            {post.category_id && (
                <link rel="canonical" href={`https://my-blogs.today/category/${post.category_id}`} />
            )}
            {post.blog_id && (
                <link rel="canonical" href={`https://my-blogs.today/blog/${post.blog_id}`} />
            )}
        </Helmet>
    );
};

export default AddMetaTags;

