import React from "react";

const ShowPopularPosts = ({popularPosts}) => {
    return (
        <div className="tab-pane fade show active" id="pills-popular" role="tabpanel"
             aria-labelledby="pills-popular-tab">
            {popularPosts.map((blogPost, index) => (
                    <div key={index} className="post-entry-1 border-bottom">
                        <div className="post-meta"><span className="date">{blogPost.category}</span> <span
                            className="mx-1"></span> <span>{blogPost.published_at}</span>
                        </div>
                        <h2 className="mb-2"><a href={`/blog/${blogPost.id}`}>{blogPost.summary}</a></h2>
                        {/*<span className="author mb-3 d-block">Jenny Wilson</span>*/}
                    </div>
            ))}
        </div>
    );
};

export default ShowPopularPosts;